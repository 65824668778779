<template>
  <v-card>
    <AdministrationSanitizationPgHeader></AdministrationSanitizationPgHeader>
    <section class="form-full-width">
      <FormAdministrationSanitizationPg :loading="loading" :getAdministrationSanitizationPg="getAdministrationSanitizationPg" @beforeUpdate="updateAdministrationSanitizationPg"></FormAdministrationSanitizationPg>
    </section>
  </v-card>
</template>

<script>
import FormAdministrationSanitizationPg from '@/components/forms/FormAdministrationSanitizationPg.vue'
import AdministrationSanitizationPgHeader from './AdministrationSanitizationPgHeader.vue'
import useAdministrationSanitizationPg from '@/composables/useAdministrationSanitizationPg'

export default {
  components: {
    FormAdministrationSanitizationPg,
    AdministrationSanitizationPgHeader,
  },
  setup() {
    // Composition API
    const {
      administrationSanitizationPg,
      loading,
      // methods
      getAdministrationSanitizationPg,
      updateAdministrationSanitizationPg,
    } = useAdministrationSanitizationPg()

    return {
      administrationSanitizationPg,
      loading,
      // methods
      getAdministrationSanitizationPg,
      updateAdministrationSanitizationPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
